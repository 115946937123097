import { createStore } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import i18n from '@/translations/i18n'
import Cookies from 'js-cookie';

const store = createStore({
  state: {
    locale: 'et',
    location: '',
    service: '',
    is_package: false,
    amount: 1,
    duration: 1,
    people_per_plan: 1,
    minimum_amount: 1,
    maximum_amount: 1,
    dessert: '',
    book_date: moment().format("DD.MM.YYYY"),
    book_time: '',
    full_name: '',
    email: '',
    phone: '',
    gift_card_code: '',
    validated_gift_cards: [],
    available_locations: [],
    available_standard_services: [],
    available_plan_services: [],
    available_times: [],
    is_booked: false,
    errors: [],
    is_loading: false,
    additional_info: '',
    additional_question_answer: '',
    additional_question: null,
    route_name: '',
    redirect_url: null,
    gift_card_checking: false,
    gift_card_error: false,
    quantity_name: '',
    discount_code: '',
    discount_code_valid: false,
    discount_code_checking: false,
    business_customer_code: null,
    business_customer_code_valid: false,
    business_customer_code_checking: false,
    has_booking_last_hour: false,
  },
  mutations: {
    setLocation(state, value) {
      state.location = value
    },
    setService(state, value) {
      state.service = value
    },
    setIsPackage(state, value) {
      state.is_package = value
    },
    setDessert(state, value) {
      state.dessert = value
    },
    setBookDate(state, value) {
      state.book_date = value
    },
    setTime(state, value) {
      state.book_time = value
    },
    setFullName(state, value) {
      state.full_name = value
    },
    setEmail(state, value) {
      state.email = value
    },
    setPhone(state, value) {
      state.phone = value
    },
    setAdditionalInfo(state, value) {
      state.additional_info = value
    },
    setGiftCardCode(state, value) {
      state.gift_card_code = value
    },
    setDiscountCode(state, value) {
      state.discount_code = value
    },
    setBusinessCustomerCode(state, value) {
      state.business_customer_code = value
    },
    setAvailableLocations(state, value) {
      state.available_locations = value
    },
    setAvailableStandardServices(state, value) {
      state.available_standard_services = value
    },
    setAvailablePlanServices(state, value) {
      state.available_plan_services = value
    },
    setAvailableTimes(state, value) {
      state.available_times = value
    },
    setIsBooked(state, value) {
      state.is_booked = value
    },
    setErrors(state, value) {
      state.errors = value
    },
    setIsLoading(state, value) {
      state.is_loading = value
    },
    setAmount(state, value) {
      state.amount = value
    },
    setPeoplePerPlan(state, value) {
      state.people_per_plan = value
    },
    setMinimumAmount(state, value) {
      state.minimum_amount = value
    },
    setMaximumAmount(state, value) {
      state.maximum_amount = value
    },
    setDuration(state, value) {
      state.duration = value
    },
    decrementAmount(state) {
      if (state.amount > state.minimum_amount) {
        state.amount--;
      }
    },
    incrementAmount(state) {
      if (state.amount < state.maximum_amount) {
        state.amount++;
      }
    },
    decrementDuration(state) {
      if (state.duration > 1) {
        state.duration--;
      }
    },
    incrementDuration(state) {
      state.duration++;
    },
    setAdditionalQuestionAnswer(state, value) {
      state.additional_question_answer = value
    },
    setAdditionalQuestion(state, value) {
      state.additional_question = value
    },
    setRouteName(state, value) {
      state.route_name = value
    },
    setRedirectUrl(state, value) {
      state.redirect_url = value
    },
    setGiftCardChecking(state, value) {
      state.gift_card_checking = value
    },
    setGiftCardError(state, value) {
      state.gift_card_error = value
    },
    setDiscountCodeValid(state, value) {
      state.discount_code_valid = value
    },
    setDiscountCodeChecking(state, value) {
      state.discount_code_checking = value
    },
    setBusinessCustomerCodeValid(state, value) {
      state.business_customer_code_valid = value
    },
    setBusinessCustomerCodeChecking(state, value) {
      state.business_customer_code_checking = value
    },
    clearGiftCards(state) {
      state.validated_gift_cards = []
    },
    setQuantityName(state, value) {
      state.quantity_name = value
    },
    setHasBookingLastHour(state, value) {
      state.has_booking_last_hour = value
    },
    setLocale(state, value) {
      state.locale = value
      i18n.global.locale = value
      document.title = i18n.global.t('Kuulsaal - Reservation')
    }
  },
  actions: {
    getAvailableLocations({ state, commit }) {
      axios.get(process.env.VUE_APP_API_URL + '/api/' + state.locale + '/locations').then((response) => {
        commit('setAvailableLocations', response.data)
        commit('setLocation', response.data[0].id)
      })
    },
    getAvailableStandardServices({ state, commit }) {
      if (state.location) {
        axios.get(process.env.VUE_APP_API_URL + '/api/' + state.locale + '/services/' + state.location + '/standard').then((response) => {
          commit('setAvailableStandardServices', response.data)
        })
      }
    },
    getAvailablePlanServices({ state, commit }) {
      if (state.location) {
        axios.get(process.env.VUE_APP_API_URL + '/api/' + state.locale + '/services/' + state.location + '/plan').then((response) => {
          commit('setAvailablePlanServices', response.data)
        })
      }
    },
    getAvailableTimes({ state, commit }) {
      let payload = {
        "date": state.book_date,
        "requested_service": state.service,
        "amount": state.amount,
        "duration": state.duration * 60
      }

      if (state.amount && state.service && state.book_date) {
        axios.post(process.env.VUE_APP_API_URL + '/api/availabletimes', payload).then((response) => {
          commit('setAvailableTimes', response.data)
        })
      }
    },
    checkIfHasBookingLastHour({ state, commit }) {
      commit('setHasBookingLastHour', false);

      axios.get(process.env.VUE_APP_API_URL + '/api/has-orders-last-hour/' + state.email).then((response) => {
        if (response.data.has_made_order_last_hour) {
          commit('setHasBookingLastHour', true);
        }
      });
    },
    storeBooking({ state, commit }) {
      let payload = {
        date: state.book_date,
        requested_service: state.service,
        amount: state.amount,
        requested_timeblock: state.book_time,
        customer_name: state.full_name,
        customer_email: state.email,
        customer_phone: state.phone.replace(/\s/g, ''),
        duration: state.duration * 60,
        customer_language: state.locale,
        order_meta: {

        }
      }

      // Check if there is a referral code in cookies
      const referralCode = Cookies.get('referral_code');
      payload.referral_code = referralCode ? referralCode : null;

      if (state.business_customer_code) {
        payload.business_customer_code = state.business_customer_code;

        if (state.additional_info) {
          payload.additional_info = state.additional_info;
        }
      }

      if (state.validated_gift_cards.length > 0) {
        payload.giftcards = state.validated_gift_cards
      }

      if (state.discount_code_valid) {
        payload.discount_code = state.discount_code
      }

      // Additional question answer = service option selection
      if (state.additional_question_answer) {
        payload.order_meta.service_option_selection = state.additional_question_answer;
      }

      axios.post(process.env.VUE_APP_API_URL + '/api/bookings/create', payload)
        .then((response) => {
          if (response.data.status === true) {
            if (response.data.data.payment_required) {
              commit('setRedirectUrl', response.data.data.url)
            }
            commit('setIsBooked', true)
            commit('setIsLoading', false)

            // Remove referral cookie
            Cookies.remove('referral_code')
          }
        }).catch((error) => {
          commit('setErrors', error.response.data.errors)
          commit('setIsLoading', false)
        })
    },
    giftCardCheck({ state, commit }) {
      // Set giftcard error to false
      commit('setGiftCardError', false);
      commit('setGiftCardChecking', true)

      let payload = {
        "booking_date": moment(state.book_date, 'DD.MM.YYYY').format(), // in datetime format
        "booking_start_at": state.book_time.split(' - ')[0], // from hh:ii - hh:ii to hh:ii (start_at)
        "service_id": state.service,
        "giftcard_code": state.gift_card_code
      }

      // Send API request to check the validity of gift card
      axios.post(process.env.VUE_APP_API_URL + '/api/giftcards/validate', payload)
        .then((response) => {
          // Get the status from request
          const giftcardStatus = response.data.status;

          if (giftcardStatus != 'GIFTCARD_VALID') {
            commit('setGiftCardError', giftcardStatus);
            return;
          }

          // If the giftcard was valid
          if (giftcardStatus == 'GIFTCARD_VALID') {
            // Add the gift card to validated gift cards array if it is not already there
            if (!state.validated_gift_cards.includes(state.gift_card_code)) {
              state.validated_gift_cards.push(state.gift_card_code);
            }

            // Clear the current gift card code to allow other giftcards
            state.gift_card_code = null;
          }
        }).finally(() => {
          // Set check to false regardless of the outcome
          commit('setGiftCardChecking', false);
        });
    },
    discountCodeCheck({ state, commit }) {
      commit('setDiscountCodeChecking', true)

      let payload = {
        "booking_date": moment(state.book_date, 'DD.MM.YYYY').format(), // in datetime format
        "service_id": state.service,
        "discount_code": state.discount_code
      }

      axios.post(process.env.VUE_APP_API_URL + '/api/discount_code', payload).then((response) => {
        commit('setDiscountCodeValid', response.data.discount_code_valid)
        commit('setDiscountCodeChecking', false)
      }).finally(() => {
        // Set check to false regardless of the outcome
        commit('setDiscountCodeChecking', false);
      });
    },
    businessCustomerCodeCheck({ state, commit }) {
      commit('setBusinessCustomerCodeChecking', true);

      axios.get(process.env.VUE_APP_API_URL + '/api/business_customer/' + state.business_customer_code).then((response) => {
        if (response.data.business_customer_exists) {
          commit('setBusinessCustomerCodeValid', true);
          commit('setBusinessCustomerCodeChecking', false);
        }
      }).finally(() => {
        // Set check to false regardless of the outcome
        commit('setBusinessCustomerCodeChecking', false);
      });
    }
  },
  modules: {
  }
})

export default store;