<template>
    <header>
      <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
          <a class="navbar-brand" :href="'/' + currentLocale">
            <img src="@/assets/images/logo_white.svg" alt="">
          </a>
          <div class="">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 list-group-horizontal">
              <li class="nav-item">
                <a class="nav-link" :class="{ active: currentLocale === 'et'}" aria-current="page" href="/et">EE</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{ active: currentLocale === 'en'}" href="/en">EN</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" :class="{ active: currentLocale === 'ru'}" href="/ru">RU</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const currentLocale = computed(() => store.state.locale)
</script>