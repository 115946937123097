import { createRouter, createWebHistory } from 'vue-router'
import Step1 from '../views/Step1.vue'
import Step2 from '../views/Step2.vue'
import Step3 from '../views/Step3.vue'
import Step4 from '../views/Step4.vue'
import Step5 from '../views/Step5.vue'
import BookingConfirmed from '../views/Step5.vue'
import PaymentError from '../views/PaymentError.vue'
import OrderCancelConfirm from '../views/OrderCancelConfirm.vue'
import OrderCancelled from '../views/OrderCancelled.vue'
import store from '@/store';

const acceptedLocales = ['et', 'en', 'ru'];

const routes = [
  {
    path: '/:locale?',
    name: 'Step1',
    component: Step1,
    beforeEnter: (to, from, next) => {
      const { locale } = to.params;
      if (!locale || !acceptedLocales.includes(locale)) {
        return next('/et'); // Redirect to default locale if invalid
      }
      store.commit('setLocale', locale);
      next();
    }
  },
  {
    path: '/step2',
    name: 'Step2',
    component: Step2
  },
  {
    path: '/step3',
    name: 'Step3',
    component: Step3
  },
  {
    path: '/step4',
    name: 'Step4',
    component: Step4
  },
  {
    path: '/step5',
    name: 'Step5',
    component: Step5
  },
  {
    path: '/booking-confirmed',
    name: 'BookingConfirmed',
    component: BookingConfirmed
  },
  {
    path: '/payment-error',
    name: 'PaymentError',
    component: PaymentError
  },
  {
    path: '/order-cancel-confirm/:order_number',
    name: 'OrderCancelConfirm',
    component: OrderCancelConfirm
  },
  {
    path: '/order-cancelled',
    name: 'OrderCancelled',
    component: OrderCancelled
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
